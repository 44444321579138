import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { graphql } from 'gatsby';
import useSiteMetadata from '@hooks/useSiteMetadata';
import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import {
  Header,
  AuthorsDetails,
  RelatedPosts,
  PageContainer,
  ArticleContainer,
  TocRender,
  Article,
  RightPanel,
  Banner,
} from './components';
import { getTocContent, getSeoSchema, numberFigures } from './utils';

export default function BlogPostTemplate(props) {
  const { siteURL } = useSiteMetadata();
  const breakpoints = useBreakpoint();
  const {
    markdownRemark: {
      frontmatter: {
        date,
        title,
        titleIsBlack,
        opacityOnHeader,
        thumbnail,
        authors,
        seoDescription,
        seoTitle,
        featuredImage: { publicURL },
        blogHeaderImage,
        bannerDetails,
        postBannerTemplate,
      },
      html,
      wordCount: { words },
    },
    relatedBlogPosts,
  } = props.data;
  const {
    path,
    location: { href },
    pageContext: { categoryName, subcategoryName },
  } = props;

  const relatedPosts = relatedBlogPosts.edges;

  return (
    <Layout {...props}>
      <SEO
        title={title}
        seoDescription={seoDescription}
        seoTitle={seoTitle}
        featuredImage={siteURL + publicURL}
        date={date}
        schemaMarkup={getSeoSchema(
          seoTitle,
          seoDescription,
          date,
          path,
          siteURL,
          publicURL,
          authors,
          categoryName,
          subcategoryName,
        )}
      />
      <PageContainer customPaddings="px-[24px] tablet:px-[44px] smallDesktop:px-[100px] desktop:px-[184px]">
        <Header
          thumbnail={blogHeaderImage ? blogHeaderImage : thumbnail}
          title={title}
          titleIsBlack={titleIsBlack}
          breadcrumbData={{ categoryName, subcategoryName }}
          words={words}
          href={href}
          opacityOnHeader={opacityOnHeader}
          authors={authors}
          date={date}
        />
        <div className="flex flex-col justify-between gap-[30px] smallDesktop:-mr-[90px] smallDesktop:flex-row max-w-[1160px] desktop:mr-0">
          <ArticleContainer>
            <TocRender content={getTocContent(html)} />
            <Article content={numberFigures(html)} />
          </ArticleContainer>
          {!breakpoints.md ? (
            <RightPanel bannerDetails={bannerDetails} postBannerTemplate={postBannerTemplate} />
          ) : (
            bannerDetails?.showBanner && <Banner {...bannerDetails} postBannerTemplate={postBannerTemplate} />
          )}
        </div>
        {authors && <AuthorsDetails authors={authors} />}
        <RelatedPosts posts={relatedPosts} />
        {/*<CategoryBrowser isBottom />*/}
      </PageContainer>
    </Layout>
  );
}

export const blogPostQuery = graphql`
  query BlogPostByPath($path: String!, $relatedPostsPaths: [String]!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        date
        title
        titleIsBlack
        opacityOnHeader
        seoDescription
        seoTitle
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        blogHeaderImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        thumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          extension
          publicURL
        }
        authors {
          frontmatter {
            avatar {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
            name
            surname
            bio
            position
            path
          }
        }
        bannerDetails {
          bannerButtonLabel
          bannerButtonLink
          bannerTitle
          showBanner
        }
        postBannerTemplate {
          frontmatter {
            backgroundColor
            backgroundImage {
              publicURL
            }
            lineColor
            shadowColor
            textIsBlack
          }
        }
      }
      html
      wordCount {
        words
      }
    }
    relatedBlogPosts: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "blog" }, path: { in: $relatedPostsPaths } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            path
            title
            thumbnail {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              extension
              publicURL
            }
          }
        }
      }
    }
  }
`;
