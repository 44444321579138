import { paths } from '@src/constants/pathsEnum';
import { convertToSlug } from '@pages-impl/blog/components/blog-list/utils';
export const numberFigures = htmlString => { let figureNumber = 1; const figureRegex = /<em>Caption_(.*?)<\/em>/gs; const htmlWithNumberedFigures = htmlString.replace(figureRegex, match => { return match.replace(/<em>Caption_(.*?)<\/em>/, `<em>Fig.${figureNumber++}: $1</em>`); }); return htmlWithNumberedFigures; };
export const getTocContent = htmlString => { const headerRegex = /<h[2-3][^>]*>(.*?)<\/h[2-3]>/g; const headingsStringList = htmlString.match(headerRegex); const tocHtmlString = headingsStringList ? `<div>${headingsStringList.join('')}</div>` : ''; return tocHtmlString; };
export const getSeoSchema = (seoTitle, seoDescription, date, path, siteURL, publicURL, authors, categoryName, subcategoryName) => { const authorsForSchema = []; for (let i = 0; i < authors.length; i++) {
    const item = { '@type': 'person', name: `${authors[i].frontmatter.name} ${authors[i].frontmatter.surname}`, jobTitle: authors[i].frontmatter.position, url: siteURL + authors[i].frontmatter.path };
    authorsForSchema.push(item);
} const breadcrumbItems = [{ '@type': 'ListItem', position: 1, name: 'Blog', item: siteURL + paths.BLOG }]; if (categoryName) {
    breadcrumbItems.push({ '@type': 'ListItem', position: 2, name: categoryName, item: siteURL + paths.BLOG + `${convertToSlug(categoryName)}/` });
} if (subcategoryName) {
    breadcrumbItems.push({ '@type': 'ListItem', position: 3, name: subcategoryName, item: siteURL + paths.BLOG + `${convertToSlug(categoryName)}/${convertToSlug(subcategoryName)}/` });
} breadcrumbItems.push({ '@type': 'ListItem', position: subcategoryName ? 4 : 3, name: seoTitle, item: siteURL + path }); const schema = { '@context': 'https://schema.org', '@type': 'Article', headline: seoTitle, description: seoDescription, datePublished: date, datemodified: date, mainEntityOfPage: siteURL + path, image: { '@type': 'imageObject', url: siteURL + publicURL }, publisher: { '@type': 'Organization', name: 'CodiLime', url: siteURL, logo: { '@type': 'imageObject', url: 'https://events.codilime.com/hs-fs/hubfs/Group%208713-1.png' } }, author: authorsForSchema, breadcrumb: { '@type': 'BreadcrumbList', itemListElement: breadcrumbItems } }; return schema; };
